/* eslint-disable no-console,no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyledModal } from "../PeopleHomeV2/ManageBuddies/styles";
import { CalendarHeader } from "../ChallengeDetailsV2/InviteAmigoPopUp/styles";
import { BirthdayImage, BirthdayWrapper, BirthdayCard } from "./styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { getAmigoBirthday, getWorkAnniversary, fetchAnniversaryBirthdayUsers } from "../../redux/actions";
import { ImageUrl } from "../../utils/constants";
import { convertMonthFromDate, findMonth } from '../../utils/methods';
import moment from 'moment';
import {months} from '../../../mockData';
import { ActivityDropdown, CustomMenuItem, TitleContainer } from './styles';
import {DropdownArrow} from '../AdminTeamCreation/styles'

class BirthdayWishPopup extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      currentMonth: findMonth(date.getMonth()).toLowerCase(),
      activityArrow: false,
      currentMonthCountStore:date.getMonth()+1
    }
  }

  componentDidMount() {
    const { getAmigoBirthday, getWorkAnniversary, fetchAnniversaryBirthdayUsers } = this.props;
    getAmigoBirthday();
    getWorkAnniversary();
    fetchAnniversaryBirthdayUsers();
  }

  handleRequest = (data, filter) => {
    const { peerCallBack, hidePopup } = this.props;
    hidePopup();
    peerCallBack(data, 0, filter);
  };

  handleRequestAnniversary = (data,filter) => {
    const { peerCallBack, hidePopup } = this.props;
    hidePopup();
    peerCallBack(data, 1, filter);
  };

  onSelect = (name, value, id) => {
    this.setState({
      [name]: value,
      currentMonthCountStore: id
    })
  };

  render() {
    const { show, birthdayList, hidePopup, showBirthday, anniversaryList, anniversaryBirthdayUsersData } =this.props;
    const {currentMonth, currentMonthCountStore} =this.state;
    const date = new Date();
    const month = findMonth(date.getMonth()).toLowerCase();
    const currentMonthCount=date.getMonth()+1;
    const totalBirthdayCount = birthdayList.recent_birthday.length + birthdayList.today_birthday.length + birthdayList.upcoming_birthday.length;
    const totalAnniversaryCount = anniversaryList?.recent_anniversary?.length + anniversaryList?.today_anniversary?.length + anniversaryList?.upcoming_anniversary?.length;
    return showBirthday ? (
      <StyledModal show={show} width={"755px"} height="auto">
        <CalendarHeader>
          <div className="filter-dropdown">
            <TitleContainer>
              <DropdownArrow
                alt="image"
                right="10px"
                top={this.state.activityArrow?"8px":"8px"}
                src={this.state.activityArrow? ImageUrl + "/social-feed/dropdownArrow.png": ImageUrl + "/social-feed/dropdownArrow.png"}
                style={{width:"auto", height:"18px", transform: (this.state.activityArrow?"rotate(90deg)":"")}}
              />
              {window.console.log("currentMonth :- ", currentMonth)}
              <ActivityDropdown
                title={currentMonth? this.props.t(currentMonth): "Select Activity Type"}
                notActive={!currentMonth}
                id="dropdown-recurring"
                height="36px"
                onClick={() => this.setState({ activityArrow: !this.state.activityArrow })}
              >
                {months && months.length > 0 && months.map((list) => (
                  <CustomMenuItem
                    key={list.id}
                    onClick={() => this.onSelect('currentMonth', list.key, list.id)}
                    active={currentMonth == list.name}
                  >
                    {this.props.t(list.name)}
                  </CustomMenuItem>
                ))}
              </ActivityDropdown>
            </TitleContainer>
          </div>
          <div className="title" style={{margin: "auto", marginLeft:"10px"}}> {this.props.t(`Birthday's`)}</div>
          <div className="closeButton">
            <img
              src={"/public/images/NewDashboardV2/new_close_icon.png"}
              onClick={() => hidePopup()}
            />
          </div>
        </CalendarHeader>
        {currentMonth==month?<BirthdayWrapper flex={(birthdayList.upcoming_birthday===0&&birthdayList.today_birthday===0)||(birthdayList.upcoming_birthday===0&&birthdayList.recent_birthday===0)||(birthdayList.today_birthday===0&&birthdayList.recent_birthday===0)}>
          <BirthdayImage birthday="1">
            <div className="firstContainer">
              <div className="count">{totalBirthdayCount}+</div>
            </div>
            <div className="wishContainer">
              <div className="birthday">{this.props.t("People’s Birthdays In")} {month}</div>
              <div className="subtext">
                {this.props.t("May the joy that you have spread in the past come back to you.")}{" "}
                <br />
                {this.props.t("Happy birthday!")}
              </div>
            </div>
          </BirthdayImage>
          {birthdayList && birthdayList.today_birthday && birthdayList.today_birthday.length?<div className="title">{this.props.t("Today")}</div>:null}
          <div className="birthdayWrapper">
            {birthdayList && birthdayList.today_birthday && birthdayList.today_birthday.length ?
              birthdayList.today_birthday.map((data, index) => (
                <BirthdayCard key={index}>
                  <div className="image">
                    <img src={ImageUrl + "/" + data.profile_image} />
                  </div>
                  <div className="details">
                    <div className="name">{data.name}</div>
                    <div className="date">{convertMonthFromDate(moment(data.birthday).format('MMMM DD'), this.props)}</div>
                  </div>
                  <div
                    className="button"
                    onClick={() => this.handleRequest(data)}
                  >
                    {this.props.t("Wish Them!")}
                  </div>
                </BirthdayCard>
              )):
              null
            }
          </div>
          {birthdayList && birthdayList.upcoming_birthday && birthdayList.upcoming_birthday.length?<div className="title">Upcoming</div>:null}
          <div className="birthdayWrapper">
            {birthdayList && birthdayList.upcoming_birthday && birthdayList.upcoming_birthday.length?
              birthdayList.upcoming_birthday.map((data, index) => (
                <BirthdayCard key={index}>
                  <div className="image">
                    <img src={ImageUrl + "/" + data.profile_image} />
                  </div>
                  <div className="details">
                    <div className="name">{data.name}</div>
                    <div className="date">{convertMonthFromDate(moment(data.birthday).format('MMMM DD'), this.props)}</div>
                  </div>
                  <div
                    className="button"
                    onClick={() => this.handleRequest(data)}
                  >
                    {this.props.t("Wish Them!")}
                  </div>
                </BirthdayCard>
              )):
              null
            }
          </div>
          {birthdayList && birthdayList.recent_birthday && birthdayList.recent_birthday.length?<div className="title">{this.props.t("Recent")}</div>:null}
          <div className="birthdayWrapper">
            {birthdayList && birthdayList.recent_birthday && birthdayList.recent_birthday.length?
              birthdayList.recent_birthday && birthdayList.recent_birthday.map((data, index) => (
                <BirthdayCard key={index}>
                  <div className="image">
                    <img src={ImageUrl + "/" + data.profile_image} />
                  </div>
                  <div className="details">
                    <div className="name">{data.name}</div>
                    <div className="date">{convertMonthFromDate(moment(data.birthday).format('MMMM DD'), this.props)}</div>
                  </div>
                  <div
                    className="button"
                    onClick={() => this.handleRequest(data)}
                  >
                    {this.props.t("Wish Them!")}
                  </div>
                </BirthdayCard>
              )):
              null
            }
          </div>
        </BirthdayWrapper>:
          <BirthdayWrapper padding={"20px 25px"} style={{display:"block"}}>
            <BirthdayImage birthday="1">
              <div className="firstContainer">
                <div className="count">{anniversaryBirthdayUsersData.birthday&&anniversaryBirthdayUsersData.birthday[currentMonth].length}+</div>
              </div>
              <div className="wishContainer">
                <div className="birthday">{this.props.t("People’s Birthdays In")} {currentMonth}</div>
                <div className="subtext">
                  {this.props.t("May the joy that you have spread in the past come back to you.")}{" "}<br />{this.props.t("Happy birthday!")}
                </div>
              </div>
            </BirthdayImage>
            <div className="birthdayWrapper">
              {anniversaryBirthdayUsersData && anniversaryBirthdayUsersData.birthday && anniversaryBirthdayUsersData.birthday ?
                anniversaryBirthdayUsersData.birthday[currentMonth].map((data, index) => (
                  <BirthdayCard key={index} disable={currentMonthCountStore>currentMonthCount}>
                    <div className="image">
                      <img src={ImageUrl + "/" + data.profile_image} />
                    </div>
                    <div className="details">
                      <div className="name">{data.full_name}</div>
                      <div className="date">{convertMonthFromDate(moment(data.birthday).format('MMMM DD'), this.props)}</div>
                    </div>
                    <div
                      className="button"
                      onClick={currentMonthCountStore>currentMonthCount?"": () => this.handleRequest(data,1)}
                    >
                      {this.props.t("Wish Them!")}
                    </div>
                  </BirthdayCard>)):null}
            </div>       
          </BirthdayWrapper>
        }
      </StyledModal>
    ) : (
      <StyledModal show={show} width={"755px"} height="auto">
        <CalendarHeader>
          <div className="filter-dropdown">
            <TitleContainer>
              <DropdownArrow
                alt="image"
                right="10px"
                top={this.state.activityArrow?"8px":"8px"}
                src={this.state.activityArrow? ImageUrl + "/social-feed/dropdownArrow.png": ImageUrl + "/social-feed/dropdownArrow.png"}
                style={{width:"auto", height:"18px", transform: (this.state.activityArrow?"rotate(90deg)":"")}}
              />
              <ActivityDropdown
                title={currentMonth? this.props.t(currentMonth): "Select Activity Type"}
                notActive={!currentMonth}
                id="dropdown-recurring"
                height="36px"
                onClick={() => this.setState({ activityArrow: !this.state.activityArrow })}
              >
                {months && months.length > 0 && months.map((list) => (
                  <CustomMenuItem
                    key={list.id}
                    onClick={() => this.onSelect('currentMonth', list.key, list.id)}
                    active={currentMonth == list.name}
                  >
                    {this.props.t(list.name)}
                  </CustomMenuItem>))}
              </ActivityDropdown>
            </TitleContainer>
          </div>
          <div className="title" style={{margin: "auto", marginLeft:"10px"}}> {this.props.t(`Anniversaries`)}</div>
          <div className="closeButton">
            <img
              src={"/public/images/NewDashboardV2/new_close_icon.png"}
              onClick={() => hidePopup()}
            />
          </div>
        </CalendarHeader>
        {currentMonth==month?<BirthdayWrapper flex={(anniversaryList.recent_anniversary===0&&anniversaryList.today_anniversary===0)||(anniversaryList.upcoming_anniversary===0&&anniversaryList.today_anniversary===0)||(anniversaryList.recent_anniversary===0&&anniversaryList.today_anniversary===0)}>
          <BirthdayImage>
            <div className="firstContainer">
              <div className="count">{totalAnniversaryCount}+</div>
            </div>
            <div className="wishContainer">
              <div className="birthday">{this.props.t("Work Anniversaries In")} {month}</div>
              <div className="subtext">
                {this.props.t("You add so much to this team and company.")} <br />
                {this.props.t("Congrats on your work anniversary!")}
              </div>
            </div>
          </BirthdayImage>
          {anniversaryList && anniversaryList.today_anniversary && anniversaryList.today_anniversary.length?<div className="title">{this.props.t("Today")}</div>:null}
          {anniversaryList && anniversaryList.today_anniversary && anniversaryList.today_anniversary.length?<div className="birthdayWrapper">
            {anniversaryList && anniversaryList.today_anniversary && anniversaryList.today_anniversary.length?
              anniversaryList.today_anniversary.map((data, index) => (
                <BirthdayCard key={index}>
                  <div className="image">
                    <img src={ImageUrl + "/" + data.profile_image} />
                  </div>
                  <div className="details">
                    <div className="name">{data.name}</div>
                    <div className="date">{convertMonthFromDate(moment(data.work_anniversary).format('MMMM DD'), this.props)}</div>
                  </div>
                  <div
                    className="button"
                    onClick={() => this.handleRequestAnniversary(data)}
                  >
                    {this.props.t("Shout Out!")}
                  </div>
                </BirthdayCard>
              )):
              null
            }
          </div>:null}
          {anniversaryList && anniversaryList.upcoming_anniversary && anniversaryList.upcoming_anniversary.length?<div className="title">{this.props.t("Upcoming")}</div>:null}
          {anniversaryList && anniversaryList.upcoming_anniversary && anniversaryList.upcoming_anniversary.length?<div className="birthdayWrapper">
            {anniversaryList && anniversaryList.upcoming_anniversary && anniversaryList.upcoming_anniversary.length?
              anniversaryList.upcoming_anniversary.map((data, index) => (
                <BirthdayCard key={index}>
                  <div className="image">
                    <img src={ImageUrl + "/" + data.profile_image} />
                  </div>
                  <div className="details">
                    <div className="name">{data.name}</div>
                    <div className="date">{convertMonthFromDate(moment(data.work_anniversary).format('MMMM DD'), this.props)}</div>
                  </div>
                  <div
                    className="button"
                    onClick={() => this.handleRequestAnniversary(data)}
                  >
                    {this.props.t("Shout Out!")}
                  </div>
                </BirthdayCard>
              )):
              null
            }
          </div>:null}
          {anniversaryList?.recent_anniversary?.length?<div className="title">{this.props.t("Recent")}</div>:null}
          {anniversaryList?.recent_anniversary?.length?<div className="birthdayWrapper">
            {anniversaryList?.recent_anniversary?.length?
              anniversaryList?.recent_anniversary?.map((data, index) => (
                <BirthdayCard key={index}>
                  <div className="image">
                    <img src={ImageUrl + "/" + data?.profile_image} />
                  </div>
                  <div className="details">
                    <div className="name">{data?.name}</div>
                    <div className="date">{convertMonthFromDate(moment(data?.work_anniversary).format('MMMM DD'), this.props)}</div>
                  </div>
                  <div
                    className="button"
                    onClick={() => this.handleRequestAnniversary(data)}
                  >
                    {this.props.t("Shout Out!")}
                  </div>
                </BirthdayCard>
              )):
              null
            }
          </div>:null}
        </BirthdayWrapper>:
          <BirthdayWrapper padding={"20px 25px"} style={{display:"block"}}>
            <BirthdayImage>
              <div className="firstContainer">
                <div className="count">{anniversaryBirthdayUsersData.work_anniversary&& anniversaryBirthdayUsersData.work_anniversary[currentMonth].length}+</div>
              </div>
              <div className="wishContainer">
                <div className="birthday">{this.props.t("Work Anniversaries In")} {currentMonth}</div>
                <div className="subtext">
                  {this.props.t("You add so much to this team and company.")} <br />
                  {this.props.t("Congrats on your work anniversary!")}
                </div>
              </div>
            </BirthdayImage>
            <div className="birthdayWrapper">
              {anniversaryBirthdayUsersData && anniversaryBirthdayUsersData.work_anniversary?
                anniversaryBirthdayUsersData.work_anniversary[currentMonth].map((data, index) => (
                  <BirthdayCard key={index} disable={currentMonthCountStore>currentMonthCount}>
                    <div className="image">
                      <img src={ImageUrl + "/" + data.profile_image} />
                    </div>
                    <div className="details">
                      <div className="name">{data.full_name}</div>
                      <div className="date">{convertMonthFromDate(moment(data.work_anniversary).format('MMMM DD'), this.props)}</div>
                    </div>
                    <div
                      className="button"
                      onClick={currentMonthCountStore>currentMonthCount?"": () => this.handleRequestAnniversary(data,1)}
                    >
                      {this.props.t("Shout Out!")}
                    </div>
                  </BirthdayCard>)):null}</div></BirthdayWrapper>}
      </StyledModal>
    );
  }
}

BirthdayWishPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  getAmigoBirthday: PropTypes.func,
  birthdayList: PropTypes.object,
  getWorkAnniversary: PropTypes.func,
  peerCallBack: PropTypes.func,
  anniversaryList: PropTypes.object,
  index: PropTypes.number,
  t: PropTypes.func,
  isAnniversaryCard: PropTypes.number,
  isBirthdayCard: PropTypes.number,
  show: PropTypes.bool,
  hidePopup: PropTypes.func,
  showBirthday: PropTypes.bool,
  fetchAnniversaryBirthdayUsers: PropTypes.func,
  anniversaryBirthdayUsersData: PropTypes.array
};

const mapStateToProps = (state) => ({
  birthdayList: state.social.birthdayList,
  anniversaryList: state.profileData.workAnniversaryList,
  anniversaryBirthdayUsersData: state.social.anniversaryBirthdayUsersData
});

const mapDispatchToProps = (dispatch) => ({
  getAmigoBirthday: () => dispatch(getAmigoBirthday()),
  getWorkAnniversary: () => dispatch(getWorkAnniversary()),
  fetchAnniversaryBirthdayUsers: () => dispatch(fetchAnniversaryBirthdayUsers())
});

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(BirthdayWishPopup)));
