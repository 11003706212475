/* eslint-disable no-console,no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { getAmigoBirthday } from '../../redux/actions';
import { BirthdayCardUpdate, FeedCardWrapper, UserInitialsMain } from "./styles";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ImageUrl } from '../../utils/constants';

class BirthdayWishCard extends Component {
  constructor(props) {
    super(props);
  }

  // componentDidMount(){
  //   const {getAmigoBirthday } = this.props;
  //   getAmigoBirthday();
  // }

  containsDefaultPng = (str) => {
    const defaultPngFound = str?.includes("default.png");
    return defaultPngFound;
  }

  getInitials = (inputString) =>{
    const words = inputString.split(' ');
    const initials = words.map((word) => word[0].toUpperCase());
    return initials.join('');
  }

  render() {
    const { callback, amigoBirthdayList } = this.props;
    let birthday = []
    if(amigoBirthdayList?.today_birthday){
      birthday=[ ...amigoBirthdayList.today_birthday, ...amigoBirthdayList.recent_birthday, ...amigoBirthdayList.upcoming_birthday];
    }
    return (
      <FeedCardWrapper margin="20px" height="216px" padding="12px">
        <div className='heading'>
          <div className='title-updated'>Birthdays</div>
        </div>
        <div className='wrapper'>
          <BirthdayCardUpdate>
            <div className="images">
              {birthday?.slice(0,3)?.map((data,index)=>(
                index==0?
                  <div className="image" key={index}>
                    {!this.containsDefaultPng(data.profile_image)?
                      <img src={ImageUrl + "/" +data.profile_image}/>:
                      <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.name)}`}</UserInitialsMain>}
                  </div>:
                  <div className="imageLeft" key={index}>
                    {!this.containsDefaultPng(data.profile_image)?
                      <img src={ImageUrl + "/" +data.profile_image}/>:
                      <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.name)}`}</UserInitialsMain>}
                  </div>
              ))}
            </div>
            <div className="button" onClick={() => callback()}>Share Your Best Wishes</div>
          </BirthdayCardUpdate>
        </div>
      </FeedCardWrapper>
    );
  }
}

BirthdayWishCard.propTypes = {
  birthdayList: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.func,
  callback: PropTypes.func,
  amigoBirthdayList:PropTypes.object,
  getAmigoBirthday:PropTypes.func,
};
const mapStateToProps = (state) => ({
  birthdayList: state.profileData.birthdayList,
  amigoBirthdayList: state.social.birthdayList,
})
const mapDispatchToProps = (dispatch) => ({
  getAmigoBirthday: () => dispatch(getAmigoBirthday()),
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BirthdayWishCard));
