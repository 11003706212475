/* eslint-disable no-console,no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { getWorkAnniversary } from '../../redux/actions';
import { BirthdayCardUpdate, FeedCardWrapper, UserInitialsMain } from "./styles";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ImageUrl } from '../../utils/constants';

class AnniversaryWishCard extends Component {
  constructor(props) {
    super(props);
  }

  // componentDidMount(){
  //   const { getWorkAnniversary } = this.props;
  //   getWorkAnniversary();
  // }

  fallBack = (e) => {
    e.target.src = "/public/images/neutral_avatar.svg";
  };

  containsDefaultPng = (str) => {
    const defaultPngFound = str?.includes("default.png");
    return defaultPngFound;
  }

  getInitials = (inputString) =>{
    const words = inputString.split(' ');
    const initials = words.map((word) => word[0].toUpperCase());
    return initials.join('');
  }

  render() {
    const { birthdayList, callback } =this.props;
    let anniversary = []
    if(birthdayList?.today_anniversary){
      anniversary=[ ...birthdayList.today_anniversary, ...birthdayList.recent_anniversary, ...birthdayList.upcoming_anniversary];
    }
    return (
      <FeedCardWrapper margin="20px" height="216px" padding="12px">
        <div className='heading'>
          <div className='title-updated'>Work Anniversaries</div>
        </div>
        <div className='wrapper'>
          <BirthdayCardUpdate>
            <div className="images">
              {anniversary?.slice(0,3)?.map((data,index)=>(
                index==0?
                  <div className="image" key={index}>
                    {!this.containsDefaultPng(data.profile_image)?
                      <img src={ImageUrl + "/" +data.profile_image}/>:
                      <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.name)}`}</UserInitialsMain>}
                  </div>:
                  <div className="imageLeft" key={index}>
                    {!this.containsDefaultPng(data.profile_image)?
                      <img src={ImageUrl + "/" +data.profile_image}/>:
                      <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.name)}`}</UserInitialsMain>}
                  </div>
              ))}
            </div>
            <div className="button" onClick={() => callback()}>Share Your Warm Wishes</div>
          </BirthdayCardUpdate>
        </div>
      </FeedCardWrapper>
    );
  }
}

AnniversaryWishCard.propTypes = {
  birthdayList: PropTypes.array,
  getWorkAnniversary: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func,
  callback: PropTypes.func
};
const mapStateToProps = (state) => ({
  birthdayList: state.profileData.workAnniversaryList
})
const mapDispatchToProps = (dispatch) => ({
  getWorkAnniversary: () => dispatch(getWorkAnniversary())
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AnniversaryWishCard));
